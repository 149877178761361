import type { App } from 'vue';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Vue3TouchEvents from "vue3-touch-events";
import { ID_INJECTION_KEY } from 'element-plus'
export default (app: App) => {
  app.use(Vue3TouchEvents);
  app.use(ElementPlus);
  app.provide(ID_INJECTION_KEY, {
    prefix: 1024,
    current: 0,
  })
}
